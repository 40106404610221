import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '../../models/config';
import {ConstStore} from '../store/const.store';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
   config: Config ;

  constructor(private http: HttpClient) {}

   loadConfig() {
    return  this.http
      .get<Config>('/assets/config.json')
      // .toPromise();

  }
}
