import { Injectable } from '@angular/core';
import {Employee} from '../../models/employee';
import {ApiService} from './api.service';
import {ConstStore} from '../store/const.store';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private apiService: ApiService, private constStore: ConstStore) { }

  getAdminById(id: number): any {
    return this.apiService.getById(this.constStore.adminUrl, id);
  }

}


