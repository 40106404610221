import {Component, Input, NgModule, OnInit} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-amountlabel',
  templateUrl: './amountlabel.component.html',
  styleUrls: ['./amountlabel.component.scss']
})

export class AmountlabelComponent implements OnInit {

  constructor(private currencyPipe: CurrencyPipe) { }

  @Input() amount;
  @Input() text;

  ngOnInit(): void {
  }

  generateLabel(): string{
    const formattedAmount = this.currencyPipe.transform(this.amount, 'EUR', 'symbol', '0.2-2', 'it-IT');
    if (this.text)
      return this.text + ' ' + formattedAmount;

    return formattedAmount;
  }
}
