import {Customer} from 'src/app/models/customer';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ConstStore} from '../store/const.store';
import {Employee} from '../../models/employee';
import {ChangePasswordModel} from '../../models/changePasswordModel';
import {ForgotPasswordModel} from '../../models/forgotPasswordModel';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private apiService: ApiService, private constStore: ConstStore) {
  }

  getEmployee(): any {
    return this.apiService.get(this.constStore.employeeUrl);
  }

  getEmployeeLight() {
    return this.apiService.get(this.constStore.employeeLightUrl);
  }

  deleteFile(path: string): any {
    return this.apiService.deleteFile(`${this.constStore.employeeUrl}/DeleteFile?path=${path}`);
  }

  deletePaySlip(path: string): any {
    return this.apiService.deleteFile(`${this.constStore.employeeUrl}/DeletePaySlip?path=${path}`);
  }

  deleteCvFile(path: string): any {
    return this.apiService.deleteFile(`${this.constStore.employeeUrl}/DeleteCvFile?path=${path}`);
  }

  getEmployeeById(id: number): any {
    return this.apiService.getById(this.constStore.employeeUrl, id);
  }

  putEmployee(employee: Employee): any {
    return this.apiService.put(this.constStore.putEmployeeUrl, employee);
  }

  moveEmployeeToIdle(id: string): any {
    return this.apiService.moveEmployeeToIdle(`${this.constStore.employeeUrl}/MoveToIdle?employeeId=${id}`, id);
  }

  putCustomer(customer: Customer): any {
    return this.apiService.put(this.constStore.putCustomerUrl, customer);
  }

  employeeChangePassword(passwordModel: ChangePasswordModel): any {
    return this.apiService.putChangePassword(this.constStore.employeeChangePasswordUrl, passwordModel);
  }

  getEconomicsDataByEmployeeId(id: number): any {
    return this.apiService.get(`${this.constStore.employeeUrl}/${id}/EconomicsData`);
  }

  employeeResetForgotPassword(resetPasswordModel: ForgotPasswordModel): any {
    return this.apiService.putResetForgotPassword(this.constStore.employeeResetPasswordUrl, resetPasswordModel);
  }

  employeeForgotPassword(email: string): any {
    return this.apiService.putForgotPassword(this.constStore.employeeForgotPasswordUrl, email);
  }

  postEmployeeImage(formData: FormData): any {
    return this.apiService.postEmployeeImage(this.constStore.employeeImageUrl, formData);
  }

  postEmployeePaySlip(formData: FormData): any {
    return this.apiService.postUploadFile(`${this.constStore.employeeUrl}/UploadEmployeePaySlip`, formData);
  }

  postEmployeeCv(formData: FormData, id: number): any {
    return this.apiService.postUploadFile(`${this.constStore.employeeUrl}/UploadCv/${id}`, formData);
  }

  postUploadFile(formData: FormData): any {
    return this.apiService.postUploadFile(this.constStore.employeeUploadFileUrl, formData);
  }

  postEmployee(employee: Employee): any {
    return this.apiService.post(this.constStore.registerEmployeeUrl, employee);
  }

  deleteEmployee(id: number): any {
    return this.apiService.delete(this.constStore.deleteEmployeeUrl, id);
  }

  restoreEmployee(email: string): Observable<any> {
    return this.apiService.put(this.constStore.restoreEmployeeUrl, {'email': email});
  }
}
