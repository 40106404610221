import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule, CurrencyPipe, DatePipe, registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import localeIt from '@angular/common/locales/it';
import {ForgotPasswordComponent} from './features/components/forgot-password/forgot-password.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {CoreModule} from './core/core.module';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {EncryptionService} from './core/services/encryption.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SharedModule} from './shared/shared.module';


registerLocaleData(localeIt);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};


/*
export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig().subscribe(res => {
    console.log(res);
    configService.config = {...res};
  });
}
*/




@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    MatSnackBarModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    RxReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule
  ],
  providers: [
    /* {
       provide: APP_INITIALIZER,
       useFactory: configFactory,
       multi: true,
       deps: [ConfigService]
     },*/
    /* {
       provide: APP_INITIALIZER,
       useFactory: appInitializer,
       multi: true,
       deps: [AuthService]
     },*/
    {
      provide: LOCALE_ID, useValue: 'it-IT'
    },
    {
      provide: {MAT_DATE_LOCALE},
      useValue: 'it-IT'
    },
    // EncryptionService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (es: EncryptionService) => () => es.init(),
    //   deps: [EncryptionService],
    //   multi: true
    // },
    /* { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
     DatePipe,*/
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
