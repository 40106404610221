import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-page-button',
  templateUrl: './page-button.component.html',
  styleUrls: ['./page-button.component.scss']
})
export class PageButtonComponent implements OnInit {

  @Input() buttonText;
  @Output() event = new EventEmitter();

  ngOnInit(): void {
  }

  handleClick(): void {
    this.event.emit();
  }
}
