import {Injectable} from '@angular/core';
import {
  UrlTree,
  Router, CanLoad, Route, UrlSegment,
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private authService: AuthService) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]):
    boolean
    | UrlTree
    | Observable<boolean
    | UrlTree>
    | Promise<boolean
    | UrlTree> {
    return this.authService.user$.pipe(
      map((user) => {
        if (user) {
          return true;
        } else {
          this.router.navigate(['login']);
          return false;
        }
      })
    );
  }
}
