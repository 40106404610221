import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {InputNumberService} from '../../../../core/services/input-number.service';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input-template-driven.component.html',
  styleUrls: ['./number-input-template-driven.component.scss']
})
export class NumberInputTemplateDrivenComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() classes: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() isMoney: boolean;
  @Input() value: number | string;
  @Input() pattern: string;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();


  constructor(private inputNumberService: InputNumberService) {
  }

  ngOnInit(): void {
    this.format();
  }

  valueChanged(value: any): void{
    this.value = value;
    this.valueChange.emit(this.value);
  }

  deFormat() {
  this.value = this.inputNumberService.deFormat(this.value)
  }

  format() {
    this.value = this.inputNumberService.format(this.value);
  }
}
