import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})

export class EncryptionService {

  constructor() {

  }

  init(secretKey: string) {
    Storage.prototype._setItem = Storage.prototype.setItem;
    Storage.prototype._getItem = Storage.prototype.getItem;

    Storage.prototype.setItem = function(key, value) {
      this._setItem(CryptoJS.SHA256(key).toString(), CryptoJS.AES.encrypt(value, secretKey).toString());
    };

    Storage.prototype.getItem = function(key) {
      let cryptedKey = CryptoJS.SHA256(key).toString();
      let value = this._getItem(cryptedKey);
      if (value) {
        value = CryptoJS.AES.decrypt(value, secretKey).toString(CryptoJS.enc.Utf8);
        return value;
      } else {
        return null;
      }
    };
  }

}
