import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertEmptyValue'
})
export class ConvertEmptyValuePipe implements PipeTransform {

  transform(value: string, ..._args: string[]): string {
    return value == "" || !value ? "--" : value;
  }

}
