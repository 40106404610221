import {APP_INITIALIZER, NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LayoutComponent} from './core/layout/layout.component';
import {LoginComponent} from './features/components/login/login.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ForgotPasswordComponent} from './features/components/forgot-password/forgot-password.component';
import {ForgotpasswordformComponent} from './features/components/forgotpasswordform/forgotpasswordform.component';

const routes: Routes = [
  {
    path: 'login', loadChildren: () => import('./features/components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'passwordDimenticata' , component: ForgotPasswordComponent
  },
  {
    path: 'resetPassword', component: ForgotpasswordformComponent
  },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: 'menu', loadChildren: () => import('./features/features.module')
          .then(module => module.FeaturesModule), canLoad: [AuthGuard] },
          // .then(module => module.FeaturesModule)},
      { path: '', redirectTo: 'menu', pathMatch: 'full' },
      { path: '**', redirectTo: 'menu' }
    ]
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
