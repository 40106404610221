import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() modalId: string;
  @Input() SubmitButtonType: string;
  @Input() modalSubmitId: string;
  @Input() modalExitId: string;
  @Input() submitTitle: string = 'Conferma';
  @Input() exitTitle: string = 'Esci';
  @Input() objectList: object[];
  @Input() isValid: boolean;
  @Output() submitFunction = new EventEmitter();
  @Output() exitFunction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  submit(event: any): void{
    this.submitFunction.emit(event);
  }
  exit(event: any): void{
    this.exitFunction.emit(event);
  }

}
