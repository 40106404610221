<!-- Basic Card Example -->
<div class="card shadow" style="height: 90%" >
  <div class="card-header py-3 employeeHeader">
    <a [ngClass]="{'disabled-link': !isAdmin}" [routerLink]= routePath [queryParams]= qParams>
      <h6 class="m-0 font-weight-bold text-primary">{{cardInfo}}
    </h6></a>
    <a *ngIf="isAdmin" ><i id="{{deleteCardId}}" class="fa fa-trash float-right deleteIcon" (click)= "delete($event)"></i></a>
    <button
      class="btn btn-success btn-sm rounded-1"
      *ngIf="editButton"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
      attr.data-target="#{{dataTarget}}"><i class="fa fa-edit"></i></button>
  </div>
  <div class="card-body" style="margin-top: 15px">
    <ng-content></ng-content>
  </div>
</div>
