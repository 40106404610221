import { Injectable } from '@angular/core';
import {EmployeeService} from './employee.service';
import {Observable} from 'rxjs';
import {ForgotPasswordModel} from '../../models/forgotPasswordModel';
import {ManagerService} from "./manager.service";

@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordformService {

  constructor(
    private employeeService: EmployeeService,
    private managerService: ManagerService
  ) { }

  employeeResetForgotPassword(forgotPasswordModel: ForgotPasswordModel): Observable<any> {
    return  new Observable<any>( observer => {
      this.employeeService.employeeResetForgotPassword(forgotPasswordModel).subscribe(
        response => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        },
        complete => {

        }
      );
    });
  }
  managerResetForgotPassword(forgotPasswordModel: ForgotPasswordModel): Observable<any> {
    return  new Observable<any>( observer => {
      this.managerService.managerResetPassword(forgotPasswordModel).subscribe(
        response => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        },
        complete => {

        }
      );
    });
  }
}
