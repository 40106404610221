import {Injectable} from '@angular/core';
import {Employee, EmployeeLight} from '../../models/employee';
import {FileListPath} from '../../models/fileListPath';

@Injectable({
  providedIn: 'root'
})
export class EmployeeStore {

  employeeList: Employee[];
  employeeListLight: EmployeeLight[];
  employee: Employee;
  employeeImagePath: string;
  employeeUploadFilePath: FileListPath;

  constructor() {
    this.employeeList = [];
  }

  loadEmployeeList(employeeList: Employee[]): void {
    this.employeeList = [...employeeList];
  }

  loadEmployeeListLight(employeeList: EmployeeLight[]): void {
    this.employeeListLight = [...employeeList];
  }

  loadEmployee(employee: Employee): void {
    this.employee = employee;
    this.employeeImagePath = employee.imagePath + '?' + employee.imageTimeStamp;
  }

  loadUploadFile(employee: Employee): void {
    this.employee = employee;
    this.employeeUploadFilePath = employee.fileListPath;
  }
}
