import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {DropdowncardComponent} from './dropdowncard/dropdowncard.component';
import {CollapsecardComponent} from './collapsecard/collapsecard.component';
import {BasiccardComponent} from './basiccard/basiccard.component';
import {LoadingComponent} from './loading/loading.component';
import {ModalComponent} from './modal/modal.component';
import {RouterModule} from '@angular/router';
import {SearchableDropdownComponent} from './searchable-dropdown/searchable-dropdown.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PageButtonComponent} from './page-button/page-button.component';
import {ModalOptionComponent} from './modal/modal-option/modal-option.component';
import {NumberInputTemplateDrivenComponent} from './inputs/number-input/templateDriven/number-input-template-driven.component';
import {AmountlabelComponent} from './labels/amount/amountlabel.component';
import {NumberInputReactiveComponent} from './inputs/number-input/reactiveForm/number-input-reactive.component';
import {FilterClientsPipe} from './pipes/filterClients.pipe';
import {SpinnerComponent} from './spinner/spinner.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ConvertDoubleToFormattedTimePipe} from './pipes/convertDoubleToTime';
import { ConvertEmptyValuePipe } from './pipes/convert-empty-value.pipe';


@NgModule({
  declarations: [
    DropdowncardComponent,
    CollapsecardComponent,
    BasiccardComponent,
    LoadingComponent,
    ModalComponent,
    ModalOptionComponent,
    SearchableDropdownComponent,
    PageButtonComponent,
    ModalOptionComponent,
    NumberInputTemplateDrivenComponent,
    AmountlabelComponent,
    NumberInputReactiveComponent,
    FilterClientsPipe,
    SpinnerComponent,
    ConvertDoubleToFormattedTimePipe,
    ConvertEmptyValuePipe
  ],
  exports: [
    BasiccardComponent,
    CollapsecardComponent,
    LoadingComponent,
    ModalComponent,
    ModalOptionComponent,
    SearchableDropdownComponent,
    PageButtonComponent,
    NumberInputTemplateDrivenComponent,
    AmountlabelComponent,
    NumberInputReactiveComponent,
    SpinnerComponent,
    ConvertDoubleToFormattedTimePipe,
    ConvertEmptyValuePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  providers: [
    CurrencyPipe
  ]
})
export class SharedModule {
}
