import {Injectable} from "@angular/core";
import {Admin} from "../../models/admin";
import {Manager} from "../../models/manager";
import {FileListPath} from "../../models/fileListPath";

@Injectable({
  providedIn: 'root'
})
export class ManagerStore {

  manager: Manager;
  managerList: Manager[];
  managerImagePath: string ;

  constructor() {
  }

  loadManager(manager: Manager): void {
    this.manager = manager;
    this.managerImagePath = manager.imagePath + '?' + manager.imageTimeStamp;

  }
  loadManagerList(managers: Manager[]): void {
    this.managerList = managers;
  }
}
