import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {EmployeeService} from './employee.service';
import {ManagerService} from "./manager.service";

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(
    private employeeService: EmployeeService,
    private managerService: ManagerService
  ) { }

  employeeForgotPassword(email: string): Observable<any> {
    return  new Observable<any>( observer => {
      this.employeeService.employeeForgotPassword(email).subscribe(
        response => {
          observer.next(response);
        },
        error => {
          observer.error(error);
        },
        complete => {

        }
      );
    });
  }
  managerForgotPassword(email: string): Observable<any> {
    return  new Observable<any>( observer => {
      this.managerService.managerForgotPassword(email).subscribe(
        response => {
          observer.next(response);
        },
        error => {
          observer.error(error);
        },
        complete => {

        }
      );
    });
  }
}
