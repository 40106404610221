<div class="card-body">
  <div class="row flex-column align-content-center">

    <div class="accordion" id="accordionExample">
      <div class="card shadow card">
        <div class="card-header" id="headingOne">
          <div class="mb-0" >
            <button class="btn btn-link text-center collapsebutton pl-0"
                    type="button" data-toggle="collapse"
                    [attr.data-target]="'#' + stringId"
                    aria-expanded="false"
            >
              {{ object }}
            </button>
            <a *ngIf="sandwichEnabled" class="dropdown no-arrow float-right" href="#" id="userDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-bars sandwichIcon"></i></a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu shadow"
                 aria-labelledby="userDropdown">
              <a class="dropdown-item"
                 id="manageProjectDevelopers"
                 style="font-size: 13px"
                 data-toggle="modal"
                 attr.data-target="#{{firstCollapseCardModalId}}"
                 data-backdrop="static"
                 data-keyboard="false"
                 (click)="onClick($event)">
                <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400" style="color: #364051!important;"></i>
                Manage Developers
              </a>
              <a class="dropdown-item"
                 id="editProject"
                 style="font-size: 13px"
                 data-toggle="modal"
                 attr.data-target="#{{secondCollapseCardModalId}}"
                 data-backdrop="static"
                 data-keyboard="false"
                 (click)="onClick($event)">
                <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400" style="color: #364051!important;"></i>
                Edit Project
              </a>
              <a class="dropdown-item"
                 id="deleteProject"
                 style="font-size: 13px"
                 (click)="onClick($event)">
                <i class="fa fa-trash fa-sm fa-fw mr-2 text-gray-400" style="color: #920e34!important;"></i>
                Delete Project
              </a>
            </div>
          </div>
        </div>

        <div id="{{ stringId }}" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
</div>
</div>
</div>
