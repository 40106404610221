import {AuthService} from './auth.service';
import {EncryptionService} from './encryption.service';
import {secretkey} from '../../models/cryptoKey';


export function appInitializer(authService: AuthService, encryptionService: EncryptionService): any {
  return () =>
    new Promise((resolve) => {
      encryptionService.init(secretkey);
      authService.RetrieveUser().subscribe(x => resolve());
    });
};


