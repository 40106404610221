import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Employee} from '../../models/employee';
import {EmployeeService} from './employee.service';
import {AdminService} from './admin.service';
import {Admin} from '../../models/admin';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private busy: boolean;

  constructor(
    private employeeService: EmployeeService,
    private adminService: AdminService,
    ) { }

  getEmployeeById(id: string): Observable<any> {
    this.busy = true;
    return new Observable<any>(observer => {
      this.employeeService.getEmployeeById(+id).subscribe(
        (response: Employee) => {
          observer.next(response);
          this.busy = false;
        },
        (error) => {
          observer.error(error);
        },
        (complete) => {

        }
      );
    });
  }

  getAdminById(id: string): Observable<any> {
    this.busy = true;
    return new Observable<any>(observer => {
      this.adminService.getAdminById(+id).subscribe(
        (response: Admin) => {
          observer.next(response);
          this.busy = false;
        },
        (error) => {
          observer.error(error);
        },
        (complete) => {

        }
      );
    });
  }
}
