import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) {
  }

  get(url: string): any {
    return this.httpClient.get(url);
  }

  getWithParams(url: string, params: HttpParams): any {
    return this.httpClient.get(url, {params});
  }

  getById(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getProjectById(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getCourseById(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getCoursesByDate(url: string, date: string): any {
    return this.httpClient.get(url + '/' + date);
  }

  getProjectAssignmentById(url: string, id: number): any {
    return this.httpClient.get(url + '/GetById/' + id);
  }

  getProjectAssignmentByEmployeeId(url: string, employeeId: number, monthNumber: number, yearNumber: number): any {
    return this.httpClient.get(url + `/Employee?employeeId=${employeeId}&monthNumber=${monthNumber}&yearNumber=${yearNumber}`);
  }

  getCustomerById(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getProjectsByCustomerId(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getAssetById(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getEmployeeAssetById(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getAssetTypeById(url: string, id: number): any {
    return this.httpClient.get(url + '/' + id);
  }

  getByAssetAvailable(url: string): any {
    return this.httpClient.get(url);
  }

  createExcel(url: string, id: number, params: HttpParams): Observable<any> {
    return this.httpClient.get(url + '/' + id, {params, responseType: 'blob'});
  }

  // getTimesheet(url: string, employeeId: string, startDate: string, endDate: string): any {
  //   return this.httpClient.get(url + '?employeeId=' + employeeId + '&startDate=' + startDate + '&endDate=' + endDate);
  // }
  //
  // postTimesheet(url: string, records: Record[]): any {
  //   return this.httpClient.post(url, records);
  // }

  postByProjectEmployee(url: string, obj: any): any {
    return this.httpClient.post(url, obj);
  }

  postEmployeeImage(url: string, formData: FormData): any {
    return this.httpClient.post(url, formData);
  }

  postUploadFile(url: string, formData: FormData): any {
    return this.httpClient.post(url, formData);
  }

  put(url: string, obj: any): any {
    return this.httpClient.put(url, obj);
  }

  moveEmployeeToIdle(url: string, id: string): any {
    return this.httpClient.put(url, id);
  }

  putChangePassword(url: string, obj: any): any {
    return this.httpClient.put(url, obj);
  }

  putForgotPassword(url: string, email: string): any {
    return this.httpClient.put(url, {email});
  }

  putResetForgotPassword(url: string, obj: any): any {
    return this.httpClient.put(url, obj);
  }

  patch(url: string, body: any) {
    return this.httpClient.patch(url, body);
  }

  post(url: string, obj: any): any {
    return this.httpClient.post(url, obj);
  }

  delete(url: string, id: number): any {
    return this.httpClient.delete(url + '/' + id);
  }

  deleteFromModule(url: string, id: number, moduleId: number): any {
    return this.httpClient.delete(url + '/' + `${id}/Module/${moduleId}`);
  }

  deleteProjectAssignment(url: string, id: number): any {
    return this.httpClient.delete(url + '/Delete/' + id);
  }

  deleteCustomer(url: string, id: number): any {
    return this.httpClient.delete(url + '/' + id);
  }

  deleteProject(url: string, id: number): any {
    return this.httpClient.delete(url + '/' + id);
  }

  deleteAsset(url: string, id: number): any {
    return this.httpClient.delete(url + '/' + id);
  }

  deleteEmployeeAsset(url: string, id: number): any {
    return this.httpClient.delete(url + '/' + id);
  }

  deleteAssetType(url: string, id: number): any {
    return this.httpClient.delete(url + '/' + id);
  }

  deleteFile(url: string): any {
    return this.httpClient.delete(url);
  }
}
