import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';


@Component({
  selector: 'app-collapsecard',
  templateUrl: './collapsecard.component.html',
  styleUrls: ['./collapsecard.component.scss']
})
export class CollapsecardComponent implements OnInit {

  @Input() sandwichEnabled: boolean;
  @Input() object: any;
  stringId: string;
  @Output() dropdownItemClickEvent = new EventEmitter();
  @Input() firstCollapseCardModalId: string;
  @Input() secondCollapseCardModalId: string;

  constructor() {
  }

  ngOnInit(): void {
    this.stringId = this.randomStringGenerator(3);
  }

  randomStringGenerator(length): string{
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 onClick(event: any): void{
    this.dropdownItemClickEvent.emit(event);
 }
}

