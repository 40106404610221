<!-- Sidebar -->
<div class="h-100">
  <ul
    class="navbar-nav h-100 bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      [ngClass]="envClass"
      routerLink="menu/profile"
    >
      <div class="sidebar-brand-icon rotate-n-15">
        <i class="fa fa-laugh-wink"></i>
      </div>
      <div class="sidebar-brand-text mx-3">
        <img src="{{ logo }}" height="50px" width="50px" />
      </div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <div>
      <div class="sidebar-userimage" *ngIf="isEmployee">
        <img *ngIf="employeeImage" [src]="employeeImage" alt="This is an image" />
        <i *ngIf="!employeeImage" class="fa fa-users fa-2x text-light"></i>
      </div>
      <div class="sidebar-adminimage" *ngIf="isAdmin">
        <img [src]="adminImage" alt="This is an image" />
      </div>
      <div class="sidebar-username">
        <p>{{ userName }}</p>
      </div>
      <div class="sidebar-heading">
        {{ isAdmin ? "ADMIN" : isEmployee ? "DIPENDENTE" : "COMMERCIALE" }}
      </div>
    </div>
    <!-- Nav Item - Utilities Collapse Menu -->
    <li *ngIf="isEmployee" class="nav-item-sidebar">
      <hr class="mt-0" style="border-top: 1px solid #fafffa" />
      <a
        class="nav-link-sidebar collapsed"
        style="content: none"
        [routerLink]="['menu/profile']"
      >
        <span>Profilo</span>
      </a>
    </li>
    <li *ngIf="isAdmin || isEmployee" class="nav-item-sidebar">
      <a
        class="nav-link-sidebar collapsed"
        style="content: none"
        routerLink="{{ getTimesheetRoute(isAdmin) }}"
      >
        <span>Timesheet</span>
      </a>
    </li>
<!--    <li *ngIf="isEmployee" class="nav-item-sidebar">-->
<!--      <a-->
<!--        class="nav-link-sidebar collapsed"-->
<!--        style="content: none"-->
<!--        [routerLink]="['menu/iTuoiWbs']"-->
<!--      >-->
<!--        <span>Commesse</span>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li *ngIf="isEmployee" class="nav-item-sidebar">-->
<!--      <a-->
<!--        class="nav-link-sidebar collapsed"-->
<!--        style="content: none"-->
<!--        [routerLink]="['menu/iTuoiDownload']"-->
<!--      >-->
<!--        <span>Download</span>-->
<!--      </a>-->
<!--    </li>-->
    <li *ngIf="isEmployee" class="nav-item-sidebar">
      <a
        class="nav-link-sidebar collapsed"
        style="content: none"
        [routerLink]="['menu/iTuoiDispositivi']"
      >
        <span>Dispositivi</span>
      </a>
    </li>
<!--    <li *ngIf="isEmployee" class="nav-item-sidebar">-->
<!--      <a-->
<!--        class="nav-link-sidebar collapsed"-->
<!--        style="content: none"-->
<!--        [routerLink]="['menu/leTueBustePaga']"-->
<!--      >-->
<!--        <span>Buste Paga</span>-->
<!--      </a>-->
<!--    </li>-->
    <hr class="sidebar-divider" />
    <!-- Heading -->
    <li *ngIf="isAdmin" class="nav-item li-company-managment">
      <a
        class="nav-link-sidebar collapsed"
        data-toggle="collapse"
        data-target="#collapsePagesCompany"
        aria-expanded="false"
        aria-controls="collapsePages"
      >
        <span><b> Gestione Azienda </b><i class="fa fa-chevron-down"></i></span>
      </a>
      <div class="nav-link-sidebar-container" *ngIf="isAdmin || isManager" id="collapsePagesCompany" class="collapse">

      <!-- Nav Item - Pages Collapse Menu -->

      <!-- Nav Item - Charts -->
          <li class="nav-item">
            <a
              class="nav-link-sidebar collapsed"
              routerLink="menu/riepilogoFatture"
            >
              <span>Scadenzario</span>
            </a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link-sidebar collapsed"-->
<!--              routerLink="menu/gestioneCommerciali"-->
<!--            >-->
<!--              <span>Commerciali</span>-->
<!--            </a>-->
<!--          </li>-->
          <li class="nav-item">
            <a
              class="nav-link-sidebar collapsed"
              routerLink="menu/gestioneClienti"
              d
            >
              <span>Gestione Clienti</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link-sidebar" routerLink="menu/gestioneWbs">
              <span>Gestione Commesse</span></a
            >
          </li>
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link-sidebar collapsed"-->
<!--              routerLink="menu/riepilogoProspect"-->
<!--            >-->
<!--              <span>Gestione Prospect</span>-->
<!--            </a>-->
<!--          </li>-->
          <li class="nav-item">
            <a
              class="nav-link-sidebar collapsed"
              routerLink="menu/gestioneDipendenti"
            >
              <span>Gestione Dipendenti</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link-sidebar collapsed"
              routerLink="menu/gestioneDispositivi"

            >
              <span>Gestione Dispositivi</span>
            </a>
          </li>


      <!--  <li class="nav-item">-->
      <!--    <a class="nav-link collapsed" routerLink="menu/riepilogoCandidature" >-->
      <!--      <span>Proposition</span>-->
      <!--    </a>-->

      <!--  </li>-->
      <!-- Divider -->
      <hr class="sidebar-divider d-none d-md-block mb-2" />
      <li *ngIf="isAdmin" class="nav-item">
        <a
          class="nav-link-sidebar collapsed"
          data-toggle="collapse"
          data-target="#collapsePagesKPICompany"
          aria-expanded="false"
          aria-controls="collapsePages"
        >
          <span><b> KPI Azienda </b> <i class="fa fa-chevron-down"></i></span>
        </a>
        <div id="collapsePagesKPICompany" class="collapse">
    <li class="nav-item">
          <a routerLink="menu/kpi/estrattoConto" class="nav-link-sidebar collapsed"
            ><span> Estratto Conto </span>
          </a>
  </li>
    <li class="nav-item">
          <a routerLink="menu/kpi/giorniDiRitardo" class="nav-link-sidebar collapsed"
            ><span> Giorni di ritardo </span>
          </a>
    </li>
    <li class="nav-item">
          <a routerLink="menu/kpi/previsionali" class="nav-link-sidebar collapsed"
            ><span> Incassi Attesi </span>
          </a>
    </li>
    <li class="nav-item">
          <a routerLink="menu/kpi/idle" class="nav-link-sidebar collapsed"
            ><span> IDLE </span>
          </a>
    </li>
    <li class="nav-item">
          <a routerLink="menu/kpi/saldoOccupazionale" class="nav-link-sidebar collapsed"
            ><span> Saldo Occupazionale </span>
          </a>
    </li>
    <li class="nav-item">
          <a routerLink="menu/kpi/scadenzaCommesse" class="nav-link-sidebar collapsed"
            ><span> Scadenza Commesse </span>
          </a>
    </li>
    <li class="nav-item">
          <a routerLink="menu/kpi/totaleAsset" class="nav-link-sidebar collapsed"
            ><span> Totale Asset </span>
          </a>
    </li>
    <li class="nav-item">
          <a routerLink="menu/kpi/markupWBS" class="nav-link-sidebar collapsed"
            ><span> Redditività Commesse </span>
          </a>
    </li>
    <li class="nav-item">
      <a routerLink="menu/kpi/markupCliente" class="nav-link-sidebar collapsed"
      ><span> Costi/Ricavi Cliente </span>
      </a>
    </li>
        </div>
      </li>
    </div>
  </li>
  <li *ngIf="isAdmin" class="nav-item li-company-managment">
    <a
      class="nav-link-sidebar collapsed"
      data-toggle="collapse"
      data-target="#collapsePagesAcademy"
      aria-expanded="false"
      aria-controls="collapsePagesAcademy"
    >
      <span><b> Gestione Academy </b><i class="fa fa-chevron-down"></i></span>
    </a>
    <div class="nav-link-sidebar-container" *ngIf="isAdmin || isManager" id="collapsePagesAcademy" class="collapse">

    <!-- Nav Item - Pages Collapse Menu -->

    <!-- Nav Item - Charts -->
        <li class="nav-item">
          <a
            class="nav-link-sidebar collapsed"
            routerLink="menu/gestioneCorsi"
          >
            <span>Gestione Corsi</span>
          </a>
        </li>
        <hr class="sidebar-divider" />
        <li *ngIf="isAdmin" class="nav-item li-company-managment">
          <a
            class="nav-link-sidebar collapsed"
            data-toggle="collapse"
            data-target="#collapsePagesKPIAcademy"
            aria-expanded="false"
            aria-controls="collapsePagesKPIAcademy"
          >
            <span><b> KPI Academy </b><i class="fa fa-chevron-down"></i></span>
          </a>
          <div class="nav-link-sidebar-container" *ngIf="isAdmin || isManager" id="collapsePagesKPIAcademy" class="collapse">

          <!-- Nav Item - Pages Collapse Menu -->

          <!-- Nav Item - Charts -->
<li class="nav-item">
                <a routerLink="menu/kpiacademy/historycorsi" class="nav-link-sidebar collapsed"
                ><span> History Corsi </span>
              </a>
  </li>
<li class="nav-item">
              <a routerLink="menu/kpiacademy/proiezioneprofittocorsi" class="nav-link-sidebar collapsed"
                ><span> Proiezione Profitto Corsi </span>
              </a>
  </li>
              </div>
            </li>
        </div>

      </li>

  </ul>
</div>


<!-- End of Sidebar -->
