import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {ConstStore} from '../store/const.store';
import {ChangePasswordModel} from '../../models/changePasswordModel';
import {Manager} from '../../models/manager';
import {ForgotPasswordModel} from "../../models/forgotPasswordModel";

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  constructor(private apiService: ApiService, private constStore: ConstStore) { }

  getManagers(): any {
    return this.apiService.get(this.constStore.managerUrl);
  }

  getManagerById(id: number): any {
    return this.apiService.getProjectById(this.constStore.managerUrl, id);
  }
  postManager(manager: Manager): any {
    return this.apiService.post(this.constStore.managerUrl, manager);
  }

  putManager(manager: Manager ): any {
    return this.apiService.put(this.constStore.managerUrl, manager);
  }

  deleteManager(id: number): any {
    return this.apiService.deleteProject(this.constStore.managerUrl, id);
  }
  managerChangePassword(passwordModel: ChangePasswordModel): any {
    return this.apiService.putChangePassword(`${this.constStore.managerUrl}/ChangePassword`, passwordModel);
  }
  managerResetPassword(resetPasswordModel: ForgotPasswordModel): any {
    return this.apiService.putResetForgotPassword(`${this.constStore.managerUrl}/ResetPassword`, resetPasswordModel);
  }
  managerForgotPassword(email: string): any {
    return this.apiService.putForgotPassword(`${this.constStore.managerUrl}/ForgotPassword`, email);
  }
  postManagerImage(formData: FormData): any {
    return this.apiService.postUploadFile(`${this.constStore.managerUrl}/UploadImage`, formData);
  }
}
