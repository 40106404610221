import { Customer } from '../../models/customer';
import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from 'src/app/models/employee';

@Pipe({
  name: 'filterEmployeePipe'
})
export class FilterClientsPipe implements PipeTransform {

  transform(clients: Customer[], userInput: string): Customer[] {
    if (userInput) {
      return clients
      .filter(client => (client.name)
        .toLowerCase()
        .indexOf(userInput.toLowerCase()) >= 0);
    }
    else {
      return clients;
    }
  }

}
