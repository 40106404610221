import {Injectable} from '@angular/core';
import {Admin} from '../../models/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminStore {

  admin: Admin;
  adminImagePath: string ;

  constructor() {}

  loadAdmin(admin: Admin): void {
    this.admin = admin;
    this.adminImagePath = admin.imagePath + '?' + admin.imageTimeStamp;
  }
}
