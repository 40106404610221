import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-basiccard',
  templateUrl: './basiccard.component.html',
  styleUrls: ['./basiccard.component.scss']
})
export class BasiccardComponent implements OnInit {

  @Input() cardInfo = 'Basic card';
  @Input() editButton = false;

  @Input() dataTarget: string;
  @Input() isAdmin: boolean;
  @Input() routePath: string;
  @Input() qParams: any;
  @Output() deleteEvent = new EventEmitter();
  @Input() deleteCardId: string;

  constructor() { }

  ngOnInit(): void {
  }

  @Input() modalForm(): void {

  }

  delete(event: any): void{
    this.deleteEvent.emit(event);
  }
}
