import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // this.authService.clearLocalStorage();
          localStorage.clear();
          localStorage.setItem('logout-event', 'logout' + Math.random());
          this.router.navigate(['login'], {
            queryParams: {returnUrl: this.router.routerState.snapshot.url},
          });
        }

        if (err.status === 409) {
          return throwError(err);
        }

        if (!environment.production) {
          console.error(err);
        }

        const error = (err && err.error && err.error.detail) || err.statusText;
        return throwError(error);
      })
    );
  }
}
