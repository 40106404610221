import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {EmployeeStore} from '../../store/employee.store';
import {Subscription} from 'rxjs';
import {Employee} from '../../../models/employee';
import {ProfileService} from '../../services/profile.service';
import {ConstStore} from '../../store/const.store';
import {AuthService} from '../../services/auth.service';
import {LayoutService} from '../../services/layout.service';
import {Admin} from '../../../models/admin';
import {AdminStore} from '../../store/admin.store';
import {ManagerStore} from '../../store/manager.store';
import {ManagermanagementService} from '../../services/managermanagement.service';
import {Manager} from '../../../models/manager';
import {EmployeeformService} from '../../services/employeeform.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {


  // tslint:disable-next-line:max-line-length
  constructor(
    public employeeStore: EmployeeStore,
    public managerStore: ManagerStore,
    public adminStore: AdminStore,
    private profileService: ProfileService,
    private managerService: ManagermanagementService,
    private employeeService: EmployeeformService,
    private authService: AuthService,
    private constStore: ConstStore,
    private layoutService: LayoutService
    ) {
  }

  readonly apiUrl = this.constStore.basePath;
  loggedUserId: string;
  getUserByIdSubscription: Subscription;
  getManagerByIdSubscription: Subscription;
  getUserImageSubscription: Subscription;
  employeeImagePath: string;
  isAdmin: boolean;
  isManager: boolean;
  isEmployee: boolean;
  busy: boolean;
  userName: string;
  userImg: string;

  ngOnInit(): void {
    this.busy = false;
    this.getUser();
    this.authService.watchStorage().subscribe(next => {
      this.getUser();
    });
  }
  getUserImage(path: string): void {
    this.busy = true;
    this.getUserImageSubscription = this.employeeService.downloadFile(path).subscribe(next => {
      this.userImg = next.url;
      this.busy = false;
    }, error => {
      this.busy = false;
    });
  }
  getUser(): void {
    this.loggedUserId = localStorage.getItem('userId');
    this.isAdmin = localStorage.getItem('accountType') === '1';
    this.isManager = localStorage.getItem('accountType') === '2';
    this.isEmployee = localStorage.getItem('accountType') === '0';

    switch (localStorage.getItem('accountType')){
      case '1':
        this.handleAdmin();
        break;
      case '2':
        this.handleManager();
        break;
      default:
        this.handleEmployee();
        break;
    }
  }
  handleEmployee(): void {
    this.busy = true;
    this.getUserByIdSubscription = this.layoutService.getEmployeeById(this.loggedUserId).subscribe(
        (response: Employee) => {
          this.employeeStore.loadEmployee(response);
          this.userName = this.employeeStore.employee?.name + ' ' + this.employeeStore.employee?.lastname;
          this.getUserImage(this.employeeStore.employee.imagePath);
          this.busy = false;
        },
        (error) => {
          this.busy = false;
        }
      );
  }
  handleAdmin(): void {
    this.busy = true;
    if (this.adminStore.admin === undefined) {
      this.getUserByIdSubscription = this.layoutService.getAdminById(this.loggedUserId).subscribe(
        (response: Admin) => {
          this.adminStore.loadAdmin(response);
          this.userName = this.adminStore.admin?.email;
          this.userImg = this.constStore.basePath + this.adminStore.admin.imagePath;
          this.busy = false;
        },
        (error) => {
          this.busy = false;
        }
      );
    }
  }
  handleManager(): void {
    this.busy = true;
    this.getManagerByIdSubscription = this.managerService.getManagerById(+this.loggedUserId).subscribe(
          (response: Manager) => {
            this.managerStore.loadManager(response);
            this.userName = this.managerStore.manager?.name + ' ' + this.managerStore.manager?.lastname;
            this.getUserImage(this.managerStore.manager.imagePath);
            this.busy = false;
          },
          (error) => {
            this.busy = false;
          }
        );
  }

  logout(): void {
    this.authService.logout().subscribe();
  }

  ngOnDestroy(): void {
    if (this.getUserByIdSubscription !== undefined) {
      this.getUserByIdSubscription.unsubscribe();
    }
    if (this.getUserImageSubscription !== undefined) {
      this.getUserImageSubscription.unsubscribe();
    }
  }

}
