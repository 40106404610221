import { Injectable } from '@angular/core';
import {Employee} from '../../models/employee';
import {Observable} from 'rxjs';
import {EmployeeService} from './employee.service';
import {EmployeeStore} from '../store/employee.store';
import {DownloadService} from "./download.service";

@Injectable({
  providedIn: 'root'
})
export class EmployeeformService {

  constructor(
    private employeeService: EmployeeService,
    private employeeStore: EmployeeStore,
    private downloadService: DownloadService
  ) {
  }

  getEmployeeById(id: string): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.getEmployeeById(+id).subscribe(
        (response: Employee) => {
          this.employeeStore.loadEmployee(response);
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        },
        (complete) => {

        }
      );
    });
  }
  getEconomicsDataByEmployeeId(id: string): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.getEconomicsDataByEmployeeId(+id).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        },
        (complete) => {

        }
      );
    });
  }
  postEmployee(employee: Employee): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.postEmployee(employee).subscribe(
        response => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        },
        complete => {

        }
      );
    });
  }

  postEmployeeCv(formData: FormData, id: number): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.postEmployeeCv(formData, id).subscribe(
        response => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        });

    });
  }
  postEmployeePaySlip(formData: FormData): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.postEmployeePaySlip(formData).subscribe(
        response => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        });

    });
  }

  postUploadFile(formData: FormData): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.postUploadFile(formData).subscribe(
        response => {
          this.employeeStore.employeeUploadFilePath = this.employeeStore.employee.fileListPath;
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        });

    });
  }
  downloadFile(path: string): Observable<any> {
    return new Observable<any>(observer => {
      this.downloadService.downloadEmployeeFile(path).subscribe(
        response => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        });

    });
  }
  deleteCvFile(path: string): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.deleteCvFile(path).subscribe(response => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        });
    });
  }
  deleteFile(path: string): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.deleteFile(path).subscribe(response => {
        observer.next(true);
      },
        error => {
        observer.error(error);
        });
    });
  }
  deletePaySlip(path: string): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.deletePaySlip(path).subscribe(response => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        });
    });
  }
  putEmployee(employee: Employee): Observable<any> {
    return  new Observable<any>( observer => {
      this.employeeService.putEmployee(employee).subscribe(
        response => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        },
        complete => {

        }
      );
    });
  }

  moveEmployeeToIdle(id: string): Observable<any> {
    return  new Observable<any>( observer => {
      this.employeeService.moveEmployeeToIdle(id).subscribe(
        response => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        },
        complete => {

        }
      );
    });
  }

  deleteEmployee(id: string): Observable<any>{
    return new Observable<any>(observer => {
      this.employeeService.deleteEmployee(+id).subscribe(
        response => {
          observer.next(response);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  restoreEmployee(email: string): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.restoreEmployee(email).subscribe(
        response => {
          observer.next(response);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
}
