<label [for]="id"><b>{{label}} {{isRequired ? '*' : ''}}</b></label>
<div class="input-group align-items-center mb-3">
  <div *ngIf="isMoney" class="input-group-prepend">
    <span class="input-group-text">€</span>
  </div>
  <input
    [id]="id"
    [name]="name"
     [pattern]="pattern"
    [ngClass]="{ 'is-invalid': (input.touched || input.dirty) &&  input.invalid, classes: true }"
    required

    class="form-control"
    [ngModel]="value"
    (ngModelChange)="valueChanged($event)"
    (blur)="format()"
    (focus)="deFormat()"
    #input="ngModel">
  <div
    *ngIf="(input.touched || input.dirty) && input.invalid"
    class="invalid-feedback">
    <div *ngIf="input.errors.required">
      Campo obbligatorio
    </div>
    <div *ngIf="input.errors.pattern">
      Formato non valido , accetta solo caratteri numerici
    </div>
  </div>
</div>
