<!-- Topbar -->
<nav *ngIf="!busy" class="navbar navbar-expand navbar-light bg-white topbar static-top shadow">

  <!-- Topbar Navbar -->
  <div class="nav-item-logo">
    <p>UNIKEY</p>
  </div>
  <ul class="navbar-nav ">

    <!-- Nav Item - User Information -->
    <li class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 medium">
          <b style="color: black">{{userName}}</b></span>
        <img *ngIf="userImg" class="img-profile rounded-circle" [src]="userImg">
        <i *ngIf="!userImg" class="fa fa-navicon text-primary mb-1"></i>
      </a>
      <!-- Dropdown - User Information -->
      <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
           aria-labelledby="userDropdown">
        <a *ngIf="!isAdmin" class="dropdown-item" [routerLink]="['menu/profile']" >
          <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400" style="color: #2e6cf6!important;"></i>
          Profilo
        </a>
        <a *ngIf="!isAdmin" class="dropdown-item" [routerLink]="['menu/cambioPassword']">
          <i class="fa fa-edit fa-sm fa-fw mr-2 text-gray-400" style="color: #4c5058!important;"></i>
          Modifica Password
        </a>
        <div *ngIf="!isAdmin" class="dropdown-divider"></div>
        <a class="dropdown-item" data-toggle="modal" data-target="#logoutModal" (click)="logout()">
          <i class="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          <b>Logout</b>
        </a>
      </div>
    </li>

  </ul>
</nav>
<!-- End of Topbar -->
