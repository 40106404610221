<input
  id="typeahead-focus"
  type="text"
  class="form-control"
  [(ngModel)]="model"
  [ngbTypeahead]="search"
  [ngClass]="{'wrongValue': isWrong}"
  placeholder="{{placeholder}}"
  (focus)="focus$.next($any($event).target.value)"
  (focusout)="onItemClick()"
  (keydown.enter)="onItemClick()"

  #instance="ngbTypeahead"
/>

