import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Project} from "../../models/project";
import {ManagerService} from './manager.service';
import {ManagerStore} from "../store/manager.store";
import {Manager} from "../../models/manager";
import {DownloadService} from "./download.service";

@Injectable({
  providedIn: 'root'
})
export class ManagermanagementService {

  constructor(
    private managerService: ManagerService,
    private downloadService: DownloadService,
    private managerStore: ManagerStore
  ) { }
  getAllManagers(): Observable<any> {
    return new Observable<any>(observer => {
      this.managerService.getManagers().subscribe(
        (response) => {
          this.managerStore.loadManagerList(response.managers);
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        },
        (complete) => {

        }
      );
    });
  }
  getManagerById(id: number): Observable<any> {
    return new Observable<any>( observer => {
      this.managerService.getManagerById(id).subscribe(
        (response: Manager) => {
          this.managerStore.loadManager(response);
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  putManager(manager: Manager): Observable<any> {
    return new Observable<any>(observer => {
      this.managerService.putManager(manager).subscribe(
        response => {
          observer.next(response);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
  postManager(manager: Manager): Observable<any> {
    return new Observable<any>( observer => {
      this.managerService.postManager(manager).subscribe(
        response => {
          observer.next(response);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  downloadFile(path: string): Observable<any> {
    return new Observable<any>(observer => {
      this.downloadService.downloadManagerFile(path).subscribe(
        response => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        });

    });
  }
  deleteManager(id: number): Observable<any>{
    return new Observable<any>(observer => {
      this.managerService.deleteManager(id).subscribe(
        response => {
          observer.next(response);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
}
