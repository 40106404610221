<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button id="{{modalSubmitId}}" attr.type="{{SubmitButtonType}}" class="btn btn-primary" data-dismiss="modal" (click)="submit($event)" [disabled]="isValid">
          {{submitTitle}} </button>
        <button id="{{modalExitId}}" type="button" class="btn btn-secondary" data-dismiss="modal" (click)="exit($event)">
          {{exitTitle}} </button>
      </div>
    </div>
  </div>
</div>
