import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Subscription} from "rxjs";
import {ForgotPasswordService} from "../../../core/services/forgot-password.service";
import {Employee} from "../../../models/employee";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  busy: boolean;
  isEmailSend = false;
  employee: Employee;
  putEmployeeSubscription: Subscription;

  constructor(private fb: FormBuilder, private forgotPasswordService: ForgotPasswordService) { }

  passwordForgotForm = this.fb.group({
    email: ['', Validators.compose(
      [Validators.required, Validators.email ]
    )]
  });


  ngOnInit(): void {
  }

  employeeForgotPassword(): void{
    this.busy = true;
    const email = this.passwordForgotForm.value.email as string;
    this.putEmployeeSubscription = this.forgotPasswordService.employeeForgotPassword(email).subscribe(
      next => {
        this.busy = false;
        this.isEmailSend = true;
      }
    );
  }
  ngOnDestroy(): void {
      this.putEmployeeSubscription?.unsubscribe();
    }
}
