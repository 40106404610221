<div class="scssForgotPassword">
<div class="text-center mb-4">
  <img
    class="mb-4 rounded-circle"
    src="../../../../assets/unikey_logo.png"
    alt="logo"
    loading="lazy"
    width="140"
    height="140"
  />
  <h1 class="h3 mb-3">Unikey</h1>
</div>
  <div *ngIf="!isEmailSend">
<b> <h6>Inserisci la tua email per il recupero della password</h6></b>
<form  [formGroup]="passwordForgotForm" class="form-signin" (ngSubmit)="employeeForgotPassword()">
  <div class="form-label-group" id="emailId">
    <input
      type="text"
      placeholder="user@email.it"
      id="email"
      formControlName="email"
      class="form-control"
      required
      autofocus
      >
  </div>

  <div>
    <button  type="submit" class="btn btn-primary mt-4">Conferma</button>
  </div>
</form>
  </div>
  <div *ngIf="isEmailSend">
    <label><h3><i>Email inviata con successo!</i></h3></label>
    <br><a [routerLink]="'/login'" style="font-size: 20px"><i>Torna alla Login</i></a>
  </div>
</div>
