import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ConstStore} from '../store/const.store';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private constStore: ConstStore
  ) {
  }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  downloadEmployeeFile(path: string): any {
    const url = `${this.constStore.employeeUrl}/DownloadFile?path=` + path;
    return this.apiService.get(url);
  }

  downloadManagerFile(path: string): any {
    const url = `${this.constStore.managerUrl}/DownloadFile?path=` + path;
    return this.apiService.get(url);
  }

  downloadCustomerFile(path: string): any {
    const url = `${this.constStore.customerUrl}/DownloadFile?path=` + path;
    return this.apiService.get(url);
  }

  downloadExcel(path: string, body: any) {
    const url = `${this.constStore.basePath}` + path;
    return this.http.post(url, body, {responseType: 'blob'}).toPromise();
  }

}
