import {Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InputNumberService {

  constructor(private currencyPipe: CurrencyPipe ) { }

  deFormat(value: number|string) {
    if(!value){
      return;
    }
    let stringedValue = value.toString();
    if(!stringedValue.match(/,/g)){
      stringedValue = stringedValue.replace('.', ',');
    }

    return stringedValue.replace(/\./g, "").replace('€', '').trim();
  }

  format(value: number|string) {
    if(!value)
      return;

    let clearedString = this.clearString(value.toString());

    let valueToFormat = clearedString.replace(/\./g, "").replace(',', '.').replace('€', '').trim();
    return this.currencyPipe.transform(valueToFormat, 'EUR', '', undefined, 'it-IT');
  }

  toNumber(value: string | number): number{
    if(!value){
      return 0;
    }

    let deFormattedString = this.deFormat(value);
    let cleanString = deFormattedString.replace(',', '.');
    return +cleanString;
  }

  private clearString(stringedValue: string): string{
    if (!stringedValue.match(/,/g)) {
      stringedValue = stringedValue.replace('.', ',');
    }
    return stringedValue;
  }
}
