import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  fromDoubleToTimeString(doubleNumber : number): {hours: string, minutes: string} {
    let splittedDoubleNumber = doubleNumber.toString().split('.');
    let hours = splittedDoubleNumber[0];
    let minutes = "00";
    if(splittedDoubleNumber.length > 1){
       minutes = Math.round(+("0."+splittedDoubleNumber[1]) * 60).toString();
    }
    if(hours.length < 2)
      hours = '0' + hours;
    if(minutes.length < 2)
      minutes = '0' + minutes;
    return {hours: hours, minutes: minutes};
  }

  formatTime(hours: string, minutes: string, format?: string): string{
    if(format === "00h00m"){
      if(hours[0] === "0")
        hours = hours[1]
      if(minutes !== "00")
        return hours+'h '+minutes+'m';
      return hours+'h';
    }
    return hours + ':' + minutes;
  }

  convertTimeToDouble(time: string) : number {
    if (!time) {
      return 0;
    }
    let splittedTime = time.split(':');
    let minutesBaseTen = +splittedTime[1] / 60;
    let totalTime = +splittedTime[0] + +minutesBaseTen.toPrecision(2);
    return totalTime;
  }

  convertDoubleToTimeStringWithFormat(doubleNumber: number, format?: string){
    let time = this.fromDoubleToTimeString(doubleNumber);
    return this.formatTime(time.hours, time.minutes, format);
  }
}
