import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { EmployeeStore } from '../../store/employee.store';
import {ConstStore} from '../../store/const.store';
import {Employee} from '../../../models/employee';
import {LayoutService} from '../../services/layout.service';
import {Admin} from '../../../models/admin';
import {Subscription} from 'rxjs';
import {AdminStore} from '../../store/admin.store';
import {Manager} from '../../../models/manager';
import {ManagerStore} from '../../store/manager.store';
import {ManagermanagementService} from '../../services/managermanagement.service';
import { EmployeeformService } from '../../services/employeeform.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  logo = '/assets/images/unikey_logo.png';
  adminImage = '/assets/images/admin_logo.png';
  readonly apiUrl = this.constStore.basePath;
  loggedUserId: string;
  getUserByIdSubscription: Subscription;
  getManagerByIdSubscription: Subscription;
  employeeImage: string;
  isAdmin: boolean;
  isEmployee: boolean;
  isManager: boolean;
  busy: boolean;
  routerLinkTimesheet: string;
  userName: string;
  getUserImageSubscription: Subscription;
  envClass: string = "env-color";

  constructor(
    public employeeStore: EmployeeStore,
    public managerStore: ManagerStore,
    private constStore: ConstStore,
    private layoutService: LayoutService,
    private employeeService: EmployeeformService,
    private managerService: ManagermanagementService,
    private adminStore: AdminStore
    ) {
  }

  ngOnInit(): void {
    this.busy = true;
    this.setEnvClass();
    this.getUser();
  }

  getUser(): void {
    this.loggedUserId = localStorage.getItem('userId');
    this.isAdmin = localStorage.getItem('accountType') === '1';
    this.isManager = localStorage.getItem('accountType') === '2';
    this.isEmployee = localStorage.getItem('accountType') === '0';

    switch (localStorage.getItem('accountType')){
      case '1':
        this.handleAdmin();
        break;
      case '2':
        this.handleManager();
        break;
      default:
        this.handleEmployee();
        break;
    }
  }

  getUserImage(path: string): void {
    this.busy = true;
    this.getUserImageSubscription = this.employeeService.downloadFile(path).subscribe(next => {
      this.employeeImage = next.url;
      this.busy = false;
    }, error => {
      this.busy = false;
    });
  }

  handleEmployee(): void {
    if (this.employeeStore.employee === undefined) {
      this.getUserByIdSubscription = this.layoutService.getEmployeeById(this.loggedUserId).subscribe(
        (response: Employee) => {
          this.employeeStore.loadEmployee(response);
          this.userName = this.employeeStore.employee?.name + ' ' + this.employeeStore.employee?.lastname;
          this.getUserImage(this.employeeStore.employee.imagePath);
          this.busy = false;
        },
        (error) => {
          this.busy = false;
        }
      );
    }
  }
  handleAdmin(): void {
    if (this.adminStore.admin === undefined) {
      this.getUserByIdSubscription = this.layoutService.getAdminById(this.loggedUserId).subscribe(
        (response: Admin) => {
          this.adminStore.loadAdmin(response);
          this.busy = false;
        },
        (error) => {
        }
      );
    }
  }
  handleManager(): void {
    if (this.managerStore.manager === undefined) {
      this.getManagerByIdSubscription = this.managerService.getManagerById(+this.loggedUserId).subscribe(
        (response: Manager) => {
          this.managerStore.loadManager(response);
          this.busy = false;
        },
        (error) => {
          this.busy = false;
        }
      );
    }
  }


  ngOnDestroy(): void {
    if (this.getUserByIdSubscription !== undefined) {
      this.getUserByIdSubscription.unsubscribe();
    }
    if (this.getManagerByIdSubscription !== undefined) {
      this.getManagerByIdSubscription.unsubscribe();
    }
    if (this.getUserImageSubscription !== undefined) {
      this.getUserImageSubscription.unsubscribe();
    }
  }

  getTimesheetRoute(isAdmin: boolean): string {
    if (isAdmin){
      return 'menu/gestioneTimesheet';
    }
    else {
      return 'menu/timesheet';
    }
  }

  setEnvClass() {
    if(environment.test)
      this.envClass = `${this.envClass}-test`;

    if(environment.collaudo)
      this.envClass = `${this.envClass}-collaudo`;

    if(environment.stage)
      this.envClass = `${this.envClass}-stage`;
  }
}
