import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputNumberService} from '../../../../core/services/input-number.service';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'app-number-input-reactive',
  templateUrl: './number-input-reactive.component.html',
  styleUrls: ['./number-input-reactive.component.scss']
})
export class NumberInputReactiveComponent implements OnInit {

  @Input() id: string;
  @Input() name: string;
  @Input() control: AbstractControl;
  @Input() classes: string;
  @Input() placeholder: string = '';
  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() isMoney: boolean;
  @Input() disabled: boolean = false;

  constructor(private inputNumberService: InputNumberService) {
  }

  ngOnInit(): void {
    this.format();
  }

  deFormat() {
    let deFormattedValue = this.inputNumberService.deFormat((this.control.value));
    this.control.setValue(deFormattedValue);
  }

  format() {
    if (!this.control) {
      return;
    }
    let formattedValue = this.inputNumberService.format((this.control.value));
    this.control.setValue(formattedValue);
  }

  getFormControl(): FormControl {
    return this.control as FormControl;
  }

  printError(control: AbstractControl) {
    console.log(control);
  }
}
