import {Component, OnDestroy, OnInit} from '@angular/core';
import {Employee} from '../../../models/employee';
import {Subscription} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {EmployeeStore} from '../../../core/store/employee.store';
import {ForgotPasswordModel} from '../../../models/forgotPasswordModel';
import {ForgotpasswordformService} from '../../../core/services/forgotpasswordform.service';
import {ActivatedRoute} from '@angular/router';
import {ConfirmPasswordValidator} from '../../../core/validators/confirm-password.validator';

@Component({
  selector: 'app-forgotpasswordform',
  templateUrl: './forgotpasswordform.component.html',
  styleUrls: ['./forgotpasswordform.component.scss']
})
export class ForgotpasswordformComponent implements OnInit, OnDestroy {

  busy: boolean;
  employee: Employee;
  employeeResetPasswordModel: ForgotPasswordModel;
  putEmployeeSubscription: Subscription;
  isPasswordChange = false;
  error: string;

  constructor(private fb: FormBuilder,
              private employeeResetForgotPassword: ForgotpasswordformService,
              public employeeStore: EmployeeStore,
              private activatedRoute: ActivatedRoute) {
    this.employeeResetPasswordModel = {} as ForgotPasswordModel;
  }

  readonly forgotPasswordForm = this.fb.group({
    nuovaPassword: [null, Validators.compose(
      [
        Validators.required,
        Validators.pattern(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z\\d\\s:]).{6,}$'))
      ]
    )],
    confermaPassword: [null, Validators.compose(
      [Validators.required]
    )]
  },{
    validators: ConfirmPasswordValidator('nuovaPassword', 'confermaPassword')
  });

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.employeeResetPasswordModel.userId = +params.get('user');
      this.employeeResetPasswordModel.token = params.get('token');
    });
  }


  employeeResetPassword(): void {
    if(this.forgotPasswordForm.value.nuovaPassword == this.forgotPasswordForm.value.confermaPassword) {
      this.busy = true;
      this.employeeResetPasswordModel.newPassword = this.forgotPasswordForm.value.nuovaPassword;
      this.putEmployeeSubscription = this.employeeResetForgotPassword.employeeResetForgotPassword(this.employeeResetPasswordModel).subscribe(
        next => {
          this.busy = false;
          this.isPasswordChange = true;
        },
        error => {
          this.error = error;
          this.busy = false;
          this.isPasswordChange = false;
          this.forgotPasswordForm.reset();
        }
      );
    }
  }

  ngOnDestroy(): void {
      this.putEmployeeSubscription?.unsubscribe();
  }

}
