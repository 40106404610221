import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject, Subscription} from 'rxjs';
import {map, tap, delay, finalize} from 'rxjs/operators';
import {ApplicationUser} from '../../models/applicationUser';
import {ConstStore} from '../store/const.store';
import {LoginResult} from '../../models/LoginResult';
import {EmployeeStore} from '../store/employee.store';
import {AdminStore} from '../store/admin.store';
import {LoginUser} from '../../models/loginUser';
import {ManagerStore} from '../store/manager.store';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private storageSub = new Subject<string>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private store: ConstStore,
    private managerStore: ManagerStore,
    public employeeStore: EmployeeStore,
    public adminStore: AdminStore) {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private apiUrl = this.store.loginUrl;
  private user = new BehaviorSubject<ApplicationUser>(null);
  user$: Observable<ApplicationUser> = this.user.asObservable();

  private storageEventListener(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      if (event.key === 'logout-event') {
        this.user.next(null);
        this.router.navigate(['login']);
      }
      if (event.key === 'login-event') {
        this.http.get<LoginResult>(this.store.loginUrl).subscribe((loginResult) => {
          this.user.next({
            userId: loginResult.id,
            username: loginResult.username,
            roles: loginResult.roles
          });
        });
      }
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  login(loginUser: LoginUser): Observable<LoginResult> {
    return this.http
      .post<LoginResult>(this.store.loginUrl, loginUser)
      .pipe(
        map((loginResult) => {
          this.user.next({
            userId: loginResult.id,
            username: loginResult.username,
            roles: loginResult.roles
          });
          this.setLocalStorage(loginResult);
          return loginResult;
        })
      );
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setStorage(key: string, data: any): void {
    localStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  RetrieveUser(): Observable<any> {
    return new Observable((observer) => {
      if (localStorage.getItem('username')) {
        this.user.next({
          userId: +localStorage.getItem('userId'),
          username: localStorage.getItem('username'),
          roles: localStorage.getItem('roles').split(',')
        });
      }
      observer.next();
    });
  }

  logout(): Observable<any> {
    return new Observable(() => {
      this.clearLocalStorage();
      this.clearStore();
      this.user.next(null);
      this.router.navigate(['login']);
    });
  }

  setLocalStorage(loginResult: LoginResult): void {
    localStorage.setItem('userId', loginResult.id.toString());
    localStorage.setItem('username', loginResult.username);
    localStorage.setItem('roles', loginResult.roles.toString());
    localStorage.setItem('accountType', loginResult.accountType.toString());
    localStorage.setItem('access_token', loginResult.token);
    localStorage.setItem('login-event', 'login' + Math.random());
  }

  clearLocalStorage(): void {
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('roles');
    localStorage.removeItem('access_token');
    localStorage.removeItem('accountType');
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }

  clearStore(): void {
    this.employeeStore.employee = undefined;
    this.adminStore.admin = undefined;
    this.managerStore.manager = undefined;
  }
}

