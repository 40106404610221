<label [for]="id"><b>{{label}} {{isRequired ? '*' : ''}}</b></label>
<div class="input-group align-items-center mb-3">
  <div *ngIf="isMoney" class="input-group-prepend">
    <span class="input-group-text">€</span>
  </div>
  <input
    [id]="id"
    [name]="name"
    [required]="isRequired"
    [placeholder]="placeholder"
    class="form-control"
    [ngClass]="classes"
    [formControl]="getFormControl()"
    [disabled]="disabled"
    [class.is-invalid]="(control?.touched || control?.dirty) && control?.invalid">

</div>
