import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {ConfigService} from '../services/configservice';

@Injectable({
  providedIn: 'root',
})
export class ConstStore {
  //url = 'http://3.120.227.90:5001';
  //url = 'https://localhost:5001';

  //URLS
  basePath: string;
  projectAssignmentUrl: string;
  loginUrl: string;
  customerUrl: string;
  managerUrl: string;
  invoiceUrl: string;
  invoicePaymentsUrl: string;
  prospectUrl: string;
  propositionUrl: string;
  representativeUrl: string;
  employeeImageUrl: string;
  employeeUploadFileUrl: string;
  employeeForgotPasswordUrl: string;
  employeeChangePasswordUrl: string;
  employeeResetPasswordUrl: string;
  putEmployeeUrl: string;
  projectUrl: string;
  postByProjectEmployeeUrl: string;
  timesheetUrl: string;
  kpiUrl: string;
  getTimeSheetByFilter: string;
  getTimeSheetByProjectAssignmentUrl: string;
  postTimesheetUrl: string;
  createExcelUrl: string;
  getTimesheetUrl: string;
  assetUrl: string;
  getByIdAssetUrl: string;
  getByAssetAvailable: string;
  postAssetUrl: string;
  putAssetUrl: string;
  deleteAssetUrl: string;
  assetTypeUrl: string;
  getAssetTypeUrl: string;
  getAssetTypeByIdUrl: string;
  putAssetTypeUrl: string;
  employeeAssetUrl: string;
  getByIdEmployeeAssetUrl: string;
  deleteEmployeeAssetUrl: string;
  registerEmployeeUrl: string;
  deleteEmployeeUrl: string;
  restoreEmployeeUrl: string;
  deleteCustomerUrl: string;
  getCourseByDate: string;
  learnerUrl: string;
  teacherUrl: string;
  employeeUrl: string;
  employeeLightUrl: string;
  economicsDataUrl: string;
  putTimeSheetUrl: string;
  patchTimesheetUrl: string;
  deleteTimesheetUrl: string;
  adminUrl: string;
  getAssetUrl: string;
  postAssetTypeUrl: string;
  deleteAssetTypeUrl: string;
  getEmployeeAssetUrl: string;
  postEmployeeAssetUrl: string;
  putEmployeeAssetUrl: string;
  registerAdminUrl: string;
  putCustomerUrl: string;
  accademyUrl: string;
  courseUrl: string;
  fullCourse: string;
  createCourseExcelUrl: string;

  foodStampValue: number[] = [5.29, 7];

  private readonly nationalHolidaysDates = [
    '01-01',
    '06-01',
    '25-04',
    '01-05',
    '02-06',
    '29-06',
    '15-08',
    '01-11',
    '08-12',
    '25-12',
    '26-12',
  ];

  public readonly listOfCities = [
    'Agrigento',
    'Alessandria',
    'Ancona',
    'Aosta',
    'Arezzo',
    'Ascoli Piceno',
    'Asti',
    'Avellino',
    'Bari',
    'BarlettaAndria-Trani',
    'Belluno',
    'Benevento',
    'Bergamo',
    'Biella',
    'Bologna',
    'Bolzano',
    'Brescia',
    'Brindisi',
    'Cagliari',
    'Caltanissetta',
    'Campobasso',
    'Carbonia-Iglesias',
    'Caserta',
    'Catania',
    'Catanzaro',
    'Chieti',
    'Como',
    'Cosenza',
    'Cremona',
    'Crotone',
    'Cuneo',
    'Enna',
    'Fermo',
    'Ferrara',
    'Firenze',
    'Foggia',
    'Forlì Cesena',
    'Frosinone',
    'Genova',
    'Gorizia',
    'Grosseto',
    'Imperia',
    'Isernia',
    'La Spezia',
    'L Aquila',
    'Latina',
    'Lecce',
    'Lecco',
    'Livorno',
    'Lodi',
    'Lucca',
    'Macerata',
    'Mantova',
    'Massa-Carrara',
    'Matera',
    'Messina',
    'Milano',
    'Modena',
    'Monza e della Brianza',
    'Napoli',
    'Novara',
    'Nuoro',
    'Olbia-Tempio',
    'Oristano',
    'Padova',
    'Palermo',
    'Parma',
    'Pavia',
    'Perugia',
    'Pesaro e Urbino',
    'Pescara',
    'Piacenza',
    'Pisa',
    'Pistoia',
    'Pordenone',
    'Potenza',
    'Prato',
    'Ragusa',
    'Ravenna',
    'Reggio Calabria',
    'Reggio Emilia',
    'Rieti',
    'Rimini',
    'Roma',
    'Rovigo',
    'Salerno',
    'Medio-Campidano',
    'Sassari',
    'Savona',
    'Siena',
    'Siracusa',
    'Sondrio',
    'Taranto',
    'Teramo',
    'Terni',
    'Torino',
    'Ogliastra',
    'Trapani',
    'Trento',
    'Treviso',
    'Trieste',
    'Udine',
    'Varese',
    'Venezia',
    'Verbano-Cusio-Ossola',
    'Vercelli',
    'Verona',
    'Vibo Valentia',
    'Vicenza',
    'Viterbo',
  ];

  public readonly livellodicontratto = [1, 2, 3, 4, 5, 6, 7];


  constructor(private datePipe: DatePipe, private configService: ConfigService) {

    this.getUrl();
  }

  getUrl() {
    if (!this.configService.config) {
      this.configService.loadConfig().subscribe(res => {
        this.basePath = res.baseUrl;
        this.setAllUrls();
      });
    }
  }

  addEasterDaysToHolidays(year: number): string {
    let easterDay: string;
    let easterMondayDay: string;

    let C = Math.floor(year / 100);
    let N = year - 19 * Math.floor(year / 19);
    let K = Math.floor((C - 17) / 25);
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor(I / 30);
    I =
      I -
      Math.floor(I / 28) *
      (1 -
        Math.floor(I / 28) *
        Math.floor(29 / (I + 1)) *
        Math.floor((21 - N) / 11));
    let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    let L = I - J;
    let M = 3 + Math.floor((L + 40) / 44);
    let D = L + 28 - 31 * Math.floor(M / 4);

    easterDay = this.datePipe.transform(new Date(year, M - 1, D), 'dd-MM');
    easterMondayDay = this.datePipe.transform(
      new Date(year, M - 1, D + 1),
      'dd-MM'
    );
    return easterMondayDay;
  }

  getHolidays(year: number): string[] {

    return [...this.nationalHolidaysDates, this.addEasterDaysToHolidays(year)];
  }

  private setAllUrls() {
    this.loginUrl = this.basePath + '/LoginUser/SignIn';

    this.customerUrl = this.basePath + '/Customer';
    this.managerUrl = this.basePath + '/Manager';
    this.invoiceUrl = this.basePath + '/Invoice';
    this.invoicePaymentsUrl = this.basePath + '/InvoicePayments';
    this.prospectUrl = this.basePath + '/Prospect';
    this.propositionUrl = this.basePath + '/Proposition';
    this.representativeUrl = this.basePath + '/Representative';
    this.employeeUrl = this.basePath + '/Employee';
    this.employeeLightUrl = this.employeeUrl + '/GetLight';
    this.economicsDataUrl = this.basePath + '/EconomicsData';
    this.employeeImageUrl = this.employeeUrl + '/UploadImage';
    this.employeeUploadFileUrl = this.employeeUrl + '/UploadFile';
    this.employeeForgotPasswordUrl = this.employeeUrl + '/ForgotPassword';
    this.employeeChangePasswordUrl = this.employeeUrl + '/ChangePassword';
    this.employeeResetPasswordUrl = this.employeeUrl + '/ResetPassword';
    this.putEmployeeUrl = this.employeeUrl + '/Put';

    this.projectUrl = this.basePath + '/Project';
    this.projectAssignmentUrl = this.basePath + '/ProjectAssignment';
    this.postByProjectEmployeeUrl = this.projectAssignmentUrl + '/GetByProjectEmployee';

    // timesheet
    this.timesheetUrl = '/Timesheet';
    this.kpiUrl = this.basePath + '/KPI';

    // postTimesheetUrl = this.employeeUrl + '/AddJobRecordList';
    this.putTimeSheetUrl = this.basePath + this.timesheetUrl;
    this.getTimeSheetByFilter = this.basePath + this.timesheetUrl + '/GetByFilter';
    this.getTimeSheetByProjectAssignmentUrl = this.basePath + this.timesheetUrl + '/GetByProjectAssignment/';
    this.postTimesheetUrl = this.basePath + this.timesheetUrl;
    this.getTimesheetUrl = this.basePath + this.timesheetUrl;
    this.patchTimesheetUrl = this.basePath + this.timesheetUrl;
    this.deleteTimesheetUrl = this.basePath + this.timesheetUrl + '/Delete';
    this.createExcelUrl = this.basePath + this.timesheetUrl + '/downloadExcel';

    this.assetUrl = this.basePath + '/Asset';
    this.getAssetUrl = this.assetUrl;
    this.getByIdAssetUrl = this.assetUrl;
    this.getByAssetAvailable = this.assetUrl + '/GetByAssetAvailability';
    this.postAssetUrl = this.assetUrl;
    this.putAssetUrl = this.assetUrl;
    this.deleteAssetUrl = this.assetUrl;

    this.assetTypeUrl = this.basePath + '/AssetType';
    this.getAssetTypeUrl = this.assetTypeUrl;
    this.getAssetTypeByIdUrl = this.assetTypeUrl;
    this.postAssetTypeUrl = this.assetTypeUrl;
    this.putAssetTypeUrl = this.assetTypeUrl;
    this.deleteAssetTypeUrl = this.assetTypeUrl;

    this.employeeAssetUrl = this.basePath + '/EmployeeAssets';
    this.getEmployeeAssetUrl = this.employeeAssetUrl;
    this.getByIdEmployeeAssetUrl = this.employeeAssetUrl;
    this.postEmployeeAssetUrl = this.employeeAssetUrl;
    this.putEmployeeAssetUrl = this.employeeAssetUrl;
    this.deleteEmployeeAssetUrl = this.employeeAssetUrl;

    this.adminUrl = this.basePath + '/Admin';
    this.registerEmployeeUrl = this.employeeUrl + '/Register';
    this.registerAdminUrl = this.adminUrl + '/Register/Admin';
    this.deleteEmployeeUrl = this.employeeUrl + '/Delete';
    this.restoreEmployeeUrl = this.employeeUrl + '/RestoreEmployee';

    // client path
    this.putCustomerUrl = this.customerUrl + '/Put';
    this.deleteCustomerUrl = this.customerUrl + '/Delete';

    //Accademy // DA RIVEDERE GLI ENDPOINT
    this.accademyUrl = this.basePath + '/Academy';
    this.courseUrl = this.accademyUrl + '/Course';
    this.fullCourse = this.courseUrl + '/Full';
    this.getCourseByDate = this.courseUrl + '/GetCoursesByDate';
    this.createCourseExcelUrl = this.basePath + '/Document/courses/history/download';
    this.learnerUrl = this.accademyUrl + '/Learner';
    this.teacherUrl = this.accademyUrl + '/Teacher';
  }

}
