<div class="modal-header">
    <div class="modal-title">
        <h5>{{modalTitle}}</h5>
    </div>
  <div class="modal-exit">
    <button id="{{modalExitId}}" type="button" class="btn exitButton" data-dismiss="modal" (click)="exit($event)">
        <i class="fa fa-times"></i> </button>
  </div>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
