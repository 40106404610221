<div id="wrapper">
  <div class=" ">
    <app-sidebar></app-sidebar>

  </div>


    <div id="content-wrapper" class="d-flex flex-column " style="overflow: hidden">

      <!-- Main Content -->
        <div id="content">
          <app-topbar></app-topbar>

            <router-outlet></router-outlet>
       </div>

     </div>
  </div>
<app-footer></app-footer>

