import { Injectable } from '@angular/core';
import {EmployeeService} from './employee.service';
import {EmployeeStore} from '../store/employee.store';
import {Observable} from 'rxjs';
import {Employee} from '../../models/employee';
import {ManagerService} from "./manager.service";
import {ManagerStore} from "../store/manager.store";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private employeeService: EmployeeService,
    private employeeStore: EmployeeStore,
    private managerStore: ManagerStore,
    private managerService: ManagerService,
    ) {
    }

  getEmployee(): Observable<any>{
    return new Observable<any>(observer => {
        this.employeeService.getEmployee().subscribe(
          (response) => {
            this.employeeStore.loadEmployeeList(response.employees);
            observer.next(response);
          },
          (err) => {
            observer.error(err);
          },
          (complete) => {

          }
        );
      }
    );
  }

  getEmployeeById(id: string): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.getEmployeeById(+id).subscribe(
        (response: Employee) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        },
        (complete) => {

        }
      );
    });
  }

  putEmployee(employee: Employee): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.putEmployee(employee).subscribe(
        response => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        },
        (complete) => {

        }
      );
    });
  }

  postEmployeeImage(formData: FormData): Observable<any> {
    return new Observable<any>(observer => {
      this.employeeService.postEmployeeImage(formData).subscribe(
      response => {
        this.employeeStore.employee.imageTimeStamp = response;
        this.employeeStore.employeeImagePath = this.employeeStore.employee.imagePath + '?' + response;
        observer.next(response);
      },
      (error) => {
        observer.error(error);
      });

    });
  }
  postManagerImage(formData: FormData): Observable<any> {
    return new Observable<any>(observer => {
      this.managerService.postManagerImage(formData).subscribe(
        response => {
          this.managerStore.manager.imageTimeStamp = response;
          this.managerStore.managerImagePath = this.managerStore.manager.imagePath + '?' + response;
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        });

    });
  }

}
