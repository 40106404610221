<div class="scssForgotPasswordForm">
  <div class="text-center mb-4">
    <img
      class="mb-4 rounded-circle"
      src="./assets/unikey_logo.png"
      alt="logo"
      loading="lazy"
      width="140"
      height="140"
    />
    <h3 class="mb-3">Unikey</h3>
  </div>
  <div *ngIf="!isPasswordChange">
  <div class="text-center">
    <h4>Reimposta la password</h4>
    <p>
      La password deve essere composta da almeno 6 caratteri,<br>
      di cui una lettera maiuscola, un numero e un carattere speciale.
    </p>
  </div>
  <form [formGroup]="forgotPasswordForm" class="form-signin" (ngSubmit)="employeeResetPassword()">
    <label><b>Nuova password</b></label>
    <div class="form-label-group">
      <input
        id="nuovaPassword"
        type="password"
        formControlName="nuovaPassword"
        class="form-control"
        required
        autofocus
        [ngClass]="{
          'is-invalid': forgotPasswordForm.controls['nuovaPassword'].invalid &&
                        forgotPasswordForm.controls['nuovaPassword'].touched
        }"
      />
      <div class="invalid-feedback"
           *ngIf="forgotPasswordForm.controls['nuovaPassword'].hasError('required') &&
                  forgotPasswordForm.controls['nuovaPassword'].touched">
        Campo obbligatorio
      </div>
      <div class="invalid-feedback"
           *ngIf="forgotPasswordForm.controls['nuovaPassword'].hasError('pattern')">
        Password non valida
      </div>
    </div>

    <label><b>Conferma password</b></label>
    <div class="form-label-group">
      <input
        id="confermaPassword"
        type="password"
        formControlName="confermaPassword"
        class="form-control"
        required
        autofocus
        [ngClass]="{
          'is-invalid': forgotPasswordForm.controls['confermaPassword'].invalid &&
                        forgotPasswordForm.controls['confermaPassword'].touched
        }"
      />
      <div class="invalid-feedback"
           *ngIf="forgotPasswordForm.controls['confermaPassword'].hasError('required') &&
                  forgotPasswordForm.controls['confermaPassword'].touched">
        Campo obbligatorio
      </div>
      <div class="invalid-feedback"
           *ngIf="forgotPasswordForm.controls['confermaPassword'].hasError('samePassword')">
        Le due password non sono uguali
      </div>
    </div>
    <div *ngIf="error" class="text-danger text-center mt-2">
      {{error}}
    </div>
    <div class="buttonconferma">
      <button id="SubmitId" type="submit" class="btn btn-primary" data-dismiss="modal" style="margin-right: 10px"
              [disabled]="forgotPasswordForm.invalid">Conferma</button></div>
  </form>
</div>
  <div *ngIf="isPasswordChange">
    <label><h3><i>Password cambiata con successo!</i></h3></label>
    <br><a [routerLink]="'/login'" style="font-size: 20px"><i>Torna alla Login</i></a>
  </div>
</div>

