import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-option',
  templateUrl: './modal-option.component.html',
  styleUrls: ['./modal-option.component.scss']
})
export class ModalOptionComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() modalId: string;
  @Input() modalExitId: string;
  @Output() exitFunction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  exit(event: any): void{
    this.exitFunction.emit(event);
  }

}
