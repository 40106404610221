import { Pipe, PipeTransform } from '@angular/core';
import {TimeService} from '../../core/services/time.service';

@Pipe({
  name: 'convertDoubleToFormattedTime'
})
export class ConvertDoubleToFormattedTimePipe implements PipeTransform {

  constructor(private timeService: TimeService) {}

  transform(doubleNumber: number, format = '00:00'): string {
    let time = this.timeService.fromDoubleToTimeString(doubleNumber);
    return this.timeService.formatTime(time.hours, time.minutes, format);
  }
}
